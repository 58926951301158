export const openApp = async (obj) => {
	//打开app的方法
	const options = {
		scheme: {
			protocol: 'qlcourses', //这个要和你们ios的工程师 沟通下拿到这个标识 才可以打开
		},
		yingyongbao: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.qlchat.courses', //应用宝的下载链接去应用宝分享出来即可 这一点很方便给老马点赞
		appstore: 'https://apps.apple.com/cn/app/%E5%8D%83%E8%81%8A%E7%89%B9%E8%AE%AD%E8%90%A5/id1526453527', //这个是ios的下载地址 打不开app就会去appstore
		fallback: 'https://m.qianliao.net/financial/download-app', //这个是app的跳转失败去的页面
		timeout: 5000, //坑来了 这里我必须写的时间长一点不然ios打开会出问题，现在ios 已经禁止了获取本地app的接口了所以现在只能通过app打开的时长来判断是否app已经下载ps:刚开始没有设置时间然后就一直打开app 后跳appstore 看了好久文档才知道是这个原因
	};
	const ua = typeof window == 'undefined' ? '': (window.navigator.userAgent || '');
    const isWX = ua.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
	const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1;
	const isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

	if (isWX) {
		let url = options.yingyongbao
		if (obj?.url) {
			if (isAndroid) {
				url = url + '&android_schema=' + encodeURIComponent(obj.url)
			}
			if (isIOS) {
				url = url + '&ios_schema=' + encodeURIComponent(obj.url)
			}
		}
		location.href = 'https://m.qianliao.net/financial/download-app';
		return
	}

	if (typeof CallApp != 'undefined') {
		const callLib = new CallApp(options);
		let result = {
			path: 'qlchat.courses/openwith',
		};
		if (obj && obj.param) {
			result.param = obj.param;
		}
		if (obj && obj.url) {
			result.path = result.path + '?path=' + obj.url;
		}
		callLib.open(result);
	} else {
		await createScript('https://media.qlchat.com/qlLive/activity/file/UC477LCH-G5LU-SQTB-1660033696172-3ZYEUHRMQ6FA.js');
		openApp(obj)
	}
};

const createScript = (src) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		document.body.appendChild(script);
	});
}
