import React, { Component } from 'react';
import { showCodeDialog } from '../../../../../components/code-open-mobile';
import { openApp } from '../../../../../lib/utils/UtilsApp';
import './index.less';

/**
 * 可选参数
 */
interface IProps {
	className?: string;
}

/**
 * 页面底部组件
 *
 * @class
 * @extends {React.Component<any>}
 */
class LeftNav extends Component<IProps> {
	public state: any = {
		showToTop: false,
	};
	/**
	 * 页面显示
	 *
	 * @returns {*}
	 */
	public componentDidMount(): void {
		// const dom: any = document.querySelector('.home-container-mobile');
		// dom.addEventListener('scroll', (): any => {
		// 	if (dom.scrollTop > dom.offsetHeight) {
		// 		if (!this.state.showToTop) {
		// 			this.setState({
		// 				showToTop: true,
		// 			});
		// 		}
		// 	} else {
		// 		if (this.state.showToTop) {
		// 			this.setState({
		// 				showToTop: false,
		// 			});
		// 		}
		// 	}
		// });
	}
	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 */
	public render(): any {
		const { className } = this.props;
		const { showToTop } = this.state;

		return (
			<div className={`left-nav-mobile ${className}`}>
				<div className="nav-list">
					<div className="nav-list-item">
						<img
							src="https://img.qlchat.com/qlLive/activity/image/4VMCROFB-67U7-BP3M-1709862449132-QAMZUITA7VF7.png"
							alt=""
							onClick={(): void => {
								window.location.href = `https://m.qianliao.net/financial/online-customer-service?source=兴趣岛h5官网`
								// showCodeDialog({
								// 	codeUrl: 'https://img.qlchat.com/qlLive/activity/image/D7FF2GP4-1PVQ-4R21-1688625008458-KKGK96K6WKB7.png?x-oss-process=image/format,webp',
								// });
							}}
						/>
						<span>在线咨询</span>
					</div>
					<div className="nav-list-item">
						<img
							src="https://img.qlchat.com/qlLive/activity/image/J7YUWONC-UL1O-1TAG-1709862447645-TBSBSMK5PS9N.png"
							alt=""
							onClick={(): void => {
								showCodeDialog({
									codeUrl: 'https://img.qlchat.com/qlLive/activity/image/RXPG6COB-QKMK-2Z8I-1687686667854-2P5P57QPIPEF.png?x-oss-process=image/format,webp',
								});
							}}
						/>
						<span>听课入口</span>
					</div>
					<div className="nav-list-item btn" onClick={(): any => openApp()}>
						打开APP
					</div>
				</div>
				{/* <div className="nav-list top" style={{
					transform: `translateX(${showToTop ? 0 : 200 }px)`
				}}>
					<div className="nav-list-item">
						<img
							src="https://img.qlchat.com/qlLive/activity/image/2GIYWD7I-OA2M-DJW2-1659690456093-53AIQSVJ9QEV.png?x-oss-process=image/format,webp"
							alt=""
							onClick={(): void => {
								let dom: any = document.querySelector('.home-container-mobile');
								dom.scrollTop = 0;
							}}
						/>
					</div>
				</div> */}
			</div>
		);
	}
}

export default LeftNav;
