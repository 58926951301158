/**
 * @description 视频弹窗
 */
import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './style.less';

/**
 * 弹窗模板
 */
export const AppDownDialog: any = (props): any => {
	let { codeUrl } = props;

	return (
		<div
			className="app-download-dialog-box-mobile"
			onClick={(): void => {
				props.onClose();
			}}>
			<div className="contain">
				<img
					className="qr-code"
					onClick={(e): void => {
						e.preventDefault();
						e.stopPropagation();
					}}
					src={ codeUrl || 'https://img.qlchat.com/qlLive/activity/image/7R2ERID5-SSD6-DFCW-1709795245602-DCA2TF8G4ZHS.png?x-oss-process=image/format,webp' }
				/>
				<img
					className="close"
					src="https://img.qlchat.com/qlLive/activity/image/JD6MW6AI-YF1V-5QTA-1653877507714-CKB7I7FHZSO9.png?x-oss-process=image/format,webp"
					onClick={(): void => {
						props.onClose();
					}}
				/>
			</div>
		</div>
	);
};

/**
 * @description 弹窗渲染
 */
export const renderDom: any = (components, props): void => {
	let rootEle: any = document.getElementById('dialog-components-body');
	if (!rootEle) {
		rootEle = document.createElement('div');
		rootEle.id = 'dialog-components-body';
		document.body.appendChild(rootEle);
	}
	ReactDOM.render(React.createElement(components, props), rootEle);
};

/**
 * @description 关闭渲染
 */
export const colseDom: any = (): void => {
	let rootEle: any = document.getElementById('dialog-components-body');
	if (rootEle) {
		ReactDOM.render(null, rootEle);
		rootEle.parentNode.removeChild(rootEle);
	}
};

/**
 * @description 开始弹窗
 */
export const showCodeDialog: any = (props = {}): void => {
	// 1. 关闭弹窗操作
	const closeDialog: any = (): Boolean => {
		colseDom();

		return false;
	};
	// 2.弹窗数据
	const obj: Object = {
		// 关闭检测弹窗
		onClose: closeDialog,
		...props,
	};
	// 3.显示检测弹窗
	renderDom(AppDownDialog, obj);
};
